<template>
  <div class="content pad-bottom--vxl">
    <div class="grid-container pad-top">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad-top">
          <h1 class="text-white large-heading pad-bottom">PCA Masterclass Agenda</h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad-top bg--white">
          <p class="agenda-details">
            13th October 2022<br />
            10:00 - 15:30<br />
            Crowne Plaza Birmingham
          </p>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad bg--white">
          <img src="../assets/agenda-one.png" />
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad bg--white">
          <img src="../assets/agenda-two.png" />
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad bg--white">
          <img src="../assets/agenda-three.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agenda',
};
</script>
